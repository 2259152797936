import React from "react"
import PageHeader from "../components/pageheader"
import { Helmet } from "react-helmet"
import Layout from "../components/layout"
import { graphql } from "gatsby"
import Gallery from "@browniebroke/gatsby-image-gallery"
import "@browniebroke/gatsby-image-gallery/dist/style.css"

const GalleryPage = ({ data }) => {
  const fullSize = data.images.edges.map((edge) => edge.node.full.fluid.src)
  const thumbs = data.images.edges.map((edge) => edge.node.thumb.fluid)
  return (
    <Layout>
      <Helmet>
        <title>Game Art</title>
      </Helmet>
      <PageHeader headerText="Game Art" />
      <div className="make__grid">
        <Gallery images={fullSize} thumbs={thumbs} />
      </div>
    </Layout>
  )
}

export const query = graphql`
  query ImagesForGallery {
    images: allFile(
      filter: { relativeDirectory: { eq: "gallery" } }
      sort: { fields: name }
    ) {
      edges {
        node {
          id
          thumb: childImageSharp {
            fluid(maxWidth: 270, maxHeight: 270) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
          full: childImageSharp {
            fluid(
              maxWidth: 1024
              quality: 85
              srcSetBreakpoints: [576, 768, 992, 1200]
            ) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }
      }
    }
  }
`

export default GalleryPage 